.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 1rem;
}

h1 {
    margin-top: 50px;
}

.search-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 2rem;
}

.search-input,
.search-button,
select {
    padding: 0.5rem;
    font-size: 1rem;
}

.search-input {
    flex-grow: 1;
    min-width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-button {
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.search-button:hover {
    background-color: #0056B3;
}

select {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
}

.results-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0;
    list-style-type: none;
}

.result-item {
    background-color: #f8f9fc;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 20px;
    width: 300px;
    margin: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 10px;
}
